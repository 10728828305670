<template>
  <div v-if="model">
    <div class="header-bar">
      <marquee behavior="scroll" direction="left">
        <div class="textDiv" v-for="(text, index) in model.headerTexts" :key="index">
          <div class="title">{{ text }}</div>
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-bar",
  props: ["data"],
  components: {},
  computed: {
    model() {
      return this.$store.state.headerBar;
    },
    headerTextAsString() {
      return this.$store.getters["headerBar/headerTextAsString"];
    },
  },
};
</script>

<style lang="scss" scoped>
.textDiv {
  display: inline;
}

.title {
  max-height: 38px;
  max-width: 100%;
  display: inline;
  margin-left: 305px;
  vertical-align: middle;
}
</style>
